<template>
    <custom-dialog :visible.sync="visible" :title="title" @close="colseDialog" @submit="submit" width="50%">
      <el-form :model="editForm" label-width="80px" :rules="rules" ref="editVersionForm">
      <el-row >
        <el-col :span="12">
          <el-form-item label="所属公司" prop="compName">
            <el-cascader
                  size="small"
                  class="block-select"
                  v-model="editForm.orgaIdArr"
                  :options="copanyListInfo"
                  popper-class="custom-cascader"
                  filterable
                  :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                   
                  }"
                  @change="cascaderEditChange">
              </el-cascader>
            <!-- <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.compName"
                placeholder="请选择所属公司" @change="companyChange">
              <el-option
                  v-for="item in companyList"
                  :key="item.compName"
                  :label="item.compName"
                  :value="item.compName"
              ></el-option>
            </el-select> -->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="程序标识" prop="built">
            <!--            <el-select-->
            <!--                class="block-select"-->
            <!--                size="small"-->
            <!--                clearable-->
            <!--                v-model="editForm.built"-->
            <!--                placeholder="请选择程序标识">-->
            <!--              <el-option-->
            <!--                  key="item.id"-->
            <!--                  label="item.name"-->
            <!--                  value="item.id"-->
            <!--              ></el-option>-->
            <!--            </el-select>-->
            <el-input size="small" v-model="editForm.built" placeholder="请输入程序标识" @blur="getVersionInfo"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属项目" prop="projName">
            <el-select
                class="block-select"
                size="small"
                clearable
                filterable
                v-model="editForm.projName"
                placeholder="请选择所属项目" @change="projectChange">
              <el-option
                  v-for="item in projectList"
                  :key="item.orgaId"
                  :label="item.orgaName"
                  :value="item.orgaName"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="版本类别" prop="type">
            <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.type"
                placeholder="请选择版本类别" @change="getVersionInfo">
              <el-option
                  v-for="item in equipType"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="选择设备" prop="duid">
            <div  v-if="!isSingle">
              <el-select
                  class="block-select"
                  size="small"
                  multiple
                  clearable
                  filterable
                  v-model="duidList"
                  placeholder="请选择设备" @change="duidChange">
                <el-option
                    v-for="(item,index) in deviceList"
                    :key="index"
                    :label="item.equipNo"
                    :value="item.equipNo"
                ></el-option>
              </el-select>
            </div>
            <el-select
                v-if="isSingle"
                class="block-select"
                size="small"
                clearable
                filterable
                v-model="editForm.duid"
                placeholder="请选择选择设备">
              <el-option
                  v-for="(item,index) in deviceList"
                  :key="index"
                  :label="item.equipNo"
                  :value="item.equipNo"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="升级包" prop="ver">
            <!-- <el-select
                class="block-select"
                size="small"
                clearable
                v-model="editForm.ver"
                placeholder="请选择升级包">
              <el-option
                  v-for="item in historyVersion"
                  :key="item.id"
                  :label="item.ver"
                  :value="item.ver"
              ></el-option>
            </el-select> -->
            {{ editForm.ver  }}
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="dismNote">
            <el-input size="small"  type="textarea"
                      :rows="2" v-model="editForm.dismNote" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    </custom-dialog>
  
  </template>
  
  <script>
  import CustomDialog from "../../components/common/CustomDialog.vue";
  import config from "../../api/config";
  import {equipType} from "../../config/dataStatus"
import {organizationCascaders, phoneReg,organizationCascader,} from "../../utils/tool";
import organizationTree from "../../api/modules/organizationTree";
import sysManage from '../../api/modules/sysManage'
  export default {
    name: "EditVersion",
    components: {CustomDialog},
    props: ['visible', 'title','isSingle'],
    data() {
      return {
        editForm: {},
      rules: {
        built:[
          { required: true, message: '请输入程序标识', trigger: 'blur' },
        ],
        type:[
          { required: true, message: '请选择版本类别', trigger: 'change' },
        ],
        duid:[
          { required: true, message: '请选择设备', trigger: 'change' },
        ],
        ver:[
          { required: true, message: '请选择升级包', trigger: 'change' },
        ],
      },
      companyList:[],
      projectList:[],
      equipType: equipType,
      deviceList:[],
      duidList:[],
      copanyListInfo:[],
      historyVersion:[]
      }
    },
    mounted() {
    // this.getCompanyList()
    // this.getDeviceList()
    this.getCopanyInfo()
  },
    methods: {
      getCopanyInfo() {
      organizationTree.getOrganizationCompanyTree().then((res) => {
        // 递归遍历 生成 {value, label, children}
       
        
        // let data = organizationCascaders(res.data);
        // console.log('data',data);
        
        this.copanyListInfo = organizationCascader(res.data);
      });
    },
    cascaderEditChange(val) {
      console.log('val',val);
      let valLen = val.length;
      this.editForm.orgaId = val[valLen - 1];
      this.getProjectList( this.editForm.orgaId)
      
    },

    // companyChange(val){
     
    //   this.editForm.projName = ''
 
    //   this.getDeviceList()

    // },
    getProjectList(val){
      sysManage.selectOrgaIdProjname({orgaId: val}).then(res =>{
        this.projectList = res.data
      })
    },
    projectChange(val){
      // 获取所选择的 项目名称
      // this.projectList.forEach(item =>{
      //   if(val == item.projId){
      //     this.editForm.projName = item.projName
      //   }
      // })
      // this.getDeviceList()

    },
    getDeviceList(){
      // 获取设备列表
      versionMange.getDeviceListVer({
        projName: this.editForm.projName,
        compName: this.editForm.compName
      }).then(res =>{
        this.deviceList = res.data

      })
    },

    getVersionInfo(val){
     
      // 获取最新版本
      const params ={
        built: this.editForm.built,
        type: this.editForm.type
      }
      sysManage.getHisUpdateVer(params).then(res =>{
        this.historyVersion = res.data
        this.editForm.ver = res.data[0].ver
        this.editForm.url = res.data[0].url
        this.$forceUpdate()
      })
      this.getBaseEquipList()

    },
    getBaseEquipList(projId,equipType){
      let params = {
        equipType:this.editForm.type,
        projName:this.editForm.projName
      }
      // this.editForm.equipNo=''
      sysManage.getProjDeviceAll(params).then(res=>{
        this.deviceList = res.data
        // res.data.map(item=>{
        //   if(item.actNo == val){ 
        //     this.editForm.equipNo = item.equipNo
         
        //   }
        // })
      })
    },
      colseDialog() {
        this.$emit('close')
      },
      submit() {
        this.$refs.editVersionForm.validate((valid) =>{
        if(valid){
          if(this.isSingle){
            // 单个升级
            const params ={
              timestamp: new Date().getTime(),
              command: 'version',
              data:{
                built: this.editForm.built,
                duid: this.editForm.duid,
                type: this.editForm.type,
                ver: this.editForm.ver,
                url: this.editForm.url,
                newest: true,
                force: true,
                versioncategory: '最新升级',
                category: 0
              }
            }

            let data ={
              topic: `F/0/KedaDebugApp/0/0/0/0/0/0/${this.editForm.duid}/Demand`,
              data: JSON.stringify(params)
            }

            sysManage.updateTerminal(data).then(res =>{
              this.$message({
                message: res.message,
                type: "success",
              });
              this.$emit('done')
            })

          }else {
            let deviceNeedUpdateEntities = []
            if(this.duidList && this.duidList.length >0){
              this.duidList.forEach(item =>{
                deviceNeedUpdateEntities.push({
                  duid: item,
                  built: this.editForm.built,
                  type: this.editForm.type,
                  ver: this.editForm.ver,
                  url: this.editForm.url,
                  newest: true,
                })
              })
           
              
              // 批量升级
              sysManage.addNeedDeviceUpdate({deviceNeedUpdateEntities:deviceNeedUpdateEntities}).then(res =>{
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.$emit('done')
              })
            }
          }
        }
      })

      },
      duidChange(val){
      if(val && val.length > 0){
        this.editForm.duid = val[0]
      }else {
        this.editForm.duid =''
      }
      this.$forceUpdate()
      this.$refs.editVersionForm.validateField('duid');
    },
      uploadSuccess(val) {
        console.log(val)
        this.editForm.url = val.data.url
        this.editForm.sourcefile = val.data.sourcefile
        this.$forceUpdate()
      },
      uploadSuccessJson(val){
        console.log(val)
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>